import '@babel/polyfill'
// Import Component hooks before component definitions
import './component-hooks'
import Vue from 'vue'
import PortalVue from 'portal-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './axios'

import './plugins/view-design.less'
import './plugins/view-design'

import './plugins/buefy'


Vue.use(PortalVue)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
