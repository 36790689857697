
import { Vue, Component } from 'vue-property-decorator'
import { GlobalStore } from '@/store'

@Component
export default class Navbar extends Vue{
	get user(){
		return GlobalStore.user
	}

	logout(){
		localStorage.clear()
		this.$router.push({ name: 'Login' })
	}
}
