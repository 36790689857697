
import { Vue, Component } from 'vue-property-decorator'
import _get from 'lodash/get'

@Component
export default class Content extends Vue {
	get routeName(){
		return _get(this.$route.meta, 'display', this.$route.name)
	}
}
