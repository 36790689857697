import Vue from 'vue'
// @ts-ignore: Buefy has messed up typings
import { ConfigProgrammatic, Button, Notification, Dropdown, Pagination} from 'buefy'

Vue.use(Button)
Vue.use(Notification)
Vue.use(Dropdown)
Vue.use(Pagination)

// @ts-ignore: Buefy has messed up typings
ConfigProgrammatic.setOptions({
	defaultIconPack: 'fas'
})
