import Vue from 'vue'
import Vuex from 'vuex'
import { extractVuexModule, createProxy } from 'vuex-class-component'
import Global from './global'
import User from './user'
import Person from './person'
import BackgroundCheck from './background-check'


Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		...extractVuexModule(Global),
		...extractVuexModule(User),
		...extractVuexModule(Person),
		...extractVuexModule(BackgroundCheck),
	}
})

export default store

const GlobalStore = createProxy(store, Global)
const UserStore = createProxy(store, User)
const PersonStore = createProxy(store, Person)
const BackgroundCheckStore = createProxy(store, BackgroundCheck)

export const vxm = {
	GlobalStore,
	UserStore,
	PersonStore,
	BackgroundCheckStore,
}

export {
	GlobalStore,
	UserStore,
	PersonStore,
	BackgroundCheckStore,
}

