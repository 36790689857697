import { DateTime } from 'luxon'
import { Decimal } from 'decimal.js'
import { Base, Model, Primed } from 'primed-model'
import { PrimedDate, PrimedDateTime, PrimedDecimal, PrimedId, PrimedUUID } from './primed-types'


@Model('User')
export class User extends Base<User> {
	id = ''
	full_name = ''
	email = ''
	is_superuser = false
	is_active = true
	is_test = true
	has_quota_limit = true
	person_quota = 0
	bgc_quota = 0

	@Primed('Company')
	company!: Company
}


@Model('Company')
export class Company extends Base<Company>{
	id = ''
	name = ''
	is_active = ''

	@Primed('Preferences')
	preferences!: Preferences

	@Primed('Contact')
	contacts!: Contact[]
}


@Model('Preferences')
export class Preferences extends Base<Preferences>{
	id = ''
	days_valid = 0
	bgc_webhook_url = ''
	bgc_webhook_secret = ''
	bgc_webhook_key = ''
}


@Model('Contact')
export class Contact extends Base<Contact>{
	id = ''
	first_name = ''
	last_name = ''
	email = ''
	phone_number = ''
}


@Model('BackgroundCheck')
export class BackgroundCheck extends Base<BackgroundCheck>{
	id = ''
	status = ''
	screening_done = true

	@Primed('Details')
	details!: Details

	@Primed('Person')
	person!: Person
}


@Model('Person')
export class Person extends Base<Person>{
	id = ''
	first_name = ''
	middle_name = ''
	last_name = ''
	second_last_name = ''
	national_id = ''
	gender = ''

	@Primed(PrimedDateTime)
	dob!: DateTime

	get dob_short(){
		return this.dob.toFormat('dd/mm/yy')
	}

	get display_name(){
		return [this.first_name, this.middle_name, this.last_name, this.second_last_name].filter(x => x).join(' ')
	}

	get names(){
		return [this.first_name, this.middle_name].filter(x => x).join(' ')
	}

	get last_names(){
		return [this.last_name, this.second_last_name].filter(x => x).join(' ')
	}
}


@Model('Details')
export class Details extends Base<Details>{
	id = ''

	@Primed('PGR')
	PRG!: PGR

	@Primed('JUD_PENAL')
	JUD_PENAL!: JUD_PENAL
}


@Model('PGR')
export class PGR extends Base<PGR>{
	id = ''
	good_standing = true
}


@Model('JUD_PENAL')
export class JUD_PENAL extends Base<JUD_PENAL>{
	id = ''

	@Primed('Case', { array: true, required: false })
	cases!: Case[]

	addNewCase(){
		this.cases.push(new Case())
	}

	clearCases(){
		this.cases = []
	}
}


@Model('Case')
export class Case extends Base<Case>{
	id = ''
	estado_actual = ''
	no_ficha = ''
	tipo_sentencia = ''
	razon = ''
	details = ''
	deported = false

	@Primed(PrimedDateTime, { required: false })
	fecha_sentencia!: DateTime

	@Primed(PrimedDateTime, { required: false })
	fecha_ingreso_prision!: DateTime

}
