
import { Vue, Component } from 'vue-property-decorator'
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Content from '@/components/Content.vue'
import AppLoading from '@/components/AppLoading.vue'
import { GlobalStore } from '@/store'


@Component({
	components: {
		Navbar,
		Sidebar,
		Content,
		AppLoading,
	}
})
export default class Main extends Vue {
	get isAuthenticated(){
		return GlobalStore.isAuthenticated
	}
}
