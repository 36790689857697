
import { Vue, Component } from 'vue-property-decorator'


@Component
export default class Sidebar extends Vue {
	adminItems = [
		{
			name: 'Dashboard',
			icon: 'ios-speedometer',
		},
		{
			name: 'Users',
			icon: 'md-contact',
		},
		{
			name: 'Persons',
			icon: 'ios-people',
		},
		{
			name: 'BackgroundChecks',
			icon: 'md-checkbox-outline',
		}
	]

	companyItems = [

	]

	get routeName(){
		return this.$route.name
	}
}
