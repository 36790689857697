
import { Component, Vue } from 'vue-property-decorator'
import { GlobalStore } from './store'


@Component({
})
export default class App extends Vue {
	created(){
		const { user } = localStorage
		if(user) {
			GlobalStore.setUser(JSON.parse(user))
			GlobalStore.setIsAuthenticated(true)
		}
	}

}
