import Vue from 'vue'
import { Button, Table, Input, FormItem, Form, Icon, Select, Option } from 'view-design'

Vue.component('i-button', Button)
Vue.component('i-table', Table)
Vue.component('i-input', Input)
Vue.component('i-form-item', FormItem)
Vue.component('i-form', Form)
Vue.component('i-icon', Icon)
Vue.component('i-select', Select)
Vue.component('i-option', Option)
