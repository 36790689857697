
import { Vue, Component, Prop } from 'vue-property-decorator'


@Component
export default class InfoWidget extends Vue {
	@Prop()
	title!: string

	@Prop()
	info!: string

	@Prop()
	subtitle!: string
}
