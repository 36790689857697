import { User } from '@/modules/models';
import { createModule, mutation, action } from 'vuex-class-component'
import axios from 'axios'
import _pickBy from 'lodash/pickBy'


const VuexModule = createModule({
	namespaced: 'User',
	strict: false,
})

export default class UserStore extends VuexModule {
	users: { [key: string]: User } = {}

	@mutation
	setUsers(users: any[]) {
		this.users = users.reduce((users, user) => ({
			...users, [user.id]: new User(user)
		}), {})
	}

	@mutation
	updateUser(user: User){
		this.users[user.id] = user
	}

	@mutation
	removeUser(ids: string[]){
		this.users = _pickBy(this.users, v => !ids.includes(v.id))
	}

	@mutation
	updateUserField(payload: { id: string, field: string, value: any }){
		const { id, field, value } = payload
		if(!this.users[id]) return

		this.users[id][field] = value
	}

	@action
	async loadUsers() {
		const { data } = await axios.get('/api/v1/users')
		this.setUsers(data)
	}
}
