import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'
import Main from '@/views/Main.vue'
import Dashboard from '@/views/Dashboard.vue'
const Users = () => import('@/views/admin/users/UserList.vue')
const BackgroundChecks = () => import('@/views/background_checks/BackgroundCheckList.vue')
const Persons = () => import('@/views/persons/PersonList.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Main',
		component: Main,
		redirect: { name: 'Dashboard' },
		children: [
			{
				path: '',
				name: 'Dashboard',
				component: Dashboard,
			},
			{
				path: 'users',
				name: 'Users',
				component: Users,
			},
			{
				path: 'background-checks',
				name: 'BackgroundChecks',
				component: BackgroundChecks,
				meta: {
					display: 'Background Checks'
				}
			},
			{
				path: 'persons',
				name: 'Persons',
				component: Persons,
			},
		],
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if(to.name !== 'Login' && !localStorage.user){
		next({ name: 'Login' })
		return
	}
	next()
})

export default router
