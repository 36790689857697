
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ChartData, ChartOptions } from 'chart.js'
import LineChartPlain from './LineChartPlain'


@Component({
	components: {
		LineChartPlain
	}
})
export default class LineChart extends Vue {
	@Prop()
	title!: string

	@Prop()
	data!: ChartData

	@Prop()
	options!: ChartOptions
}
