import { Person } from '@/modules/models';
import { createModule, mutation, action } from 'vuex-class-component'
import axios from 'axios'
import _pickBy from 'lodash/pickBy'


const VuexModule = createModule({
	namespaced: 'Person',
	strict: false,
})

export default class PersonStore extends VuexModule {
	persons: { [key: string]: Person } = {}

	@mutation
	setPersons(persons: any[]) {
		this.persons = persons.reduce((persons, person) => ({
			...persons, [person.id]: new Person(person)
		}), {})
	}

	@mutation
	updatePersons(persons: Person){
		this.persons[persons.id] = persons
	}

	@mutation
	removePersons(ids: string[]){
		this.persons = _pickBy(this.persons, v => !ids.includes(v.id))
	}

	@mutation
	updatePersonsField(payload: { id: string, field: string, value: any }){
		const { id, field, value } = payload
		if(!this.persons[id]) return

		this.persons[id][field] = value
	}

	@action
	async loadPersons() {
		const { data } = await axios.get('/api/v1/persons')
		this.setPersons(data)
	}
}
