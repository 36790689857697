
import { Vue, Component } from 'vue-property-decorator'
import InfoWidget from '@/components/dashboard/InfoWidget.vue'
import LineChart from '@/components/dashboard/LineChart.vue'

@Component({
	components: {
		InfoWidget,
		LineChart
	}
})
export default class Dashboard extends Vue {
	visible = false

	settings = {
		company: -1,
		country: -1,
		range: 'Week'
	}

	infoWidgets = [
		{
			title: 'Average Resolution Time',
			subtitle: 'hours',
			info: '2',
		},
		{
			title: 'Background Checks Today',
			subtitle: 'pending/completed',
			info: '5/5',
		},
		{
			title: 'Pass Fail Percentage',
			subtitle: 'failed',
			info: '0.2%',
		},
	]

	companies = [
		{
			name: 'All',
			id: -1
		},
		{
			name: 'OpusChecks',
			id: 1
		},
		{
			name: 'DiDi Global',
			id: 2
		}
	]

	countries = [
		{
			name: 'All',
			code: -1
		},
		{
			name: 'Dominican Republic',
			code: 'DO'
		},
		{
			name: 'Puerto Rico',
			code: 'PR'
		}
	]

	ranges = [
		{
			name: 'Week'
		},
		{
			name: 'Month'
		},
		{
			name: 'Year'
		}
	]

	data = {
		labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
		datasets: [
			{
				label: 'Data One',
				borderColor: "#bae755",
				data: [40, 20, 50, 10, 20],
			}
		]
	}

	options = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				gridLines: {
					color: "white"
				}
			}],
			yAxes: [{
				gridLines: {
					color: "white"
				},
				ticks: {
					suggestedMin: 0,
					suggestedMax: 70
				}
			}]
		}
	}

	showSettings(visible: boolean){
		this.visible = typeof visible == 'undefined' ? !this.visible : visible
	}
}
