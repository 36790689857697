
import { Vue, Component } from 'vue-property-decorator'
import { GlobalStore } from '@/store'
import { Notice } from 'view-design'
import { Form } from 'view-design'

@Component
export default class Login extends Vue {
	$refs!: { form: Form }

	user = {
		username: '',
		password: '',
	}

	rules = {
		username: [
			{ required: true, message: "Username is required" }
		],
		password: [
			{ required: true, message: "Password is required" }
		]
	}

	mounted(){
		GlobalStore.clear()
	}

	async authenticate(){
		this.$refs.form.validate(async valid => {
			if(valid){
				await GlobalStore.authenticate(this.user)
				this.$router.push({ name: "Main" })
			} else {
				this.$buefy.notification.open({
					duration: 1000,
					message: 'Usuario o Contraseña incorrecto',
					position: 'is-top-right',
					type: 'is-danger',
					hasIcon: true,
				})
			}
		})
	}
}
