import { BackgroundCheck } from '@/modules/models';
import { createModule, mutation, action } from 'vuex-class-component'
import axios from 'axios'
import _pickBy from 'lodash/pickBy'


const VuexModule = createModule({
	namespaced: 'BackgroundChecks',
	strict: false,
})

export default class BackgroundCheckStore extends VuexModule {
	backgroundChecks: { [key: string]: BackgroundCheck } = {}

	@mutation
	setBackgroundChecks(backgroundChecks: any[]) {
		this.backgroundChecks = backgroundChecks.reduce((bgcs, bgc) => ({
			...bgcs, [bgc.id]: new BackgroundCheck(bgc)
		}), {})
	}

	@mutation
	updateBackgroundCheck(backgroundCheck: BackgroundCheck){
		this.backgroundChecks[backgroundCheck.id] = backgroundCheck
	}

	@mutation
	removeBackgroundChecks(ids: string[]){
		this.backgroundChecks = _pickBy(this.backgroundChecks, v => !ids.includes(v.id))
	}

	@mutation
	updateBackgroundCheckField(payload: { id: string, field: string, value: any }){
		const { id, field, value } = payload
		if(!this.backgroundChecks[id]) return

		this.backgroundChecks[id][field] = value
	}

	@action
	async loadBackgroundChecks() {
		const { data } = await axios.get('/api/v1/background-checks')
		this.setBackgroundChecks(data)
	}
}
