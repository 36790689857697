import { Line } from 'vue-chartjs'
import { ChartData, ChartOptions } from 'chart.js'
import { Vue, Component, Prop } from 'vue-property-decorator'
import _cloneDeep from 'lodash/cloneDeep'

@Component({
	extends: Line,
})
export default class LineChartPlain extends Vue {
	renderChart!: (chartData: ChartData, options?: ChartOptions) => void

	@Prop()
	data!: ChartData

	@Prop()
	options!: ChartOptions

	watches!: any[]

	mounted () {
		this.watches = ['data', 'options'].map(key =>
			this.$watch(key, () => {
				this.renderChart(_cloneDeep(this.data), _cloneDeep(this.options))
			}, { deep: true, immediate: true })
		)
	}

	destroyed(){
		this.watches.forEach(fn => fn())
	}
}
